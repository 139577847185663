import { reactive, toRefs, watch } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import { ElRow, ElCol } from "element-plus";
import { useDebounce } from "@/hooks/core/useDebounce";
import API from "@/api/authority";
import { useMessage } from "@/hooks/web/useMessage";
export default {
  name: "BingImei",
  components: {
    Eldialog,
    ElRow,
    ElCol
  },
  emits: ["handleSuccess"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      visible: false,
      imei: "",
      id: null
    });
    watch(() => refData.visible, val => {
      if (!val) {
        refData.imei = '';
        refData.id = null;
      }
    });
    //保存
    const confirm = async () => {
      try {
        const reg = /[`~@#$%^&*_+<>:"{}.\/;'[\]]/im;
        if (reg.test(refData.imei)) throw "IMEI号不可输入特殊字符";
        const {
          msg,
          code
        } = await API.bindOrderImei({
          imei: refData.imei,
          id: refData.id
        });
        message[code === 0 ? "success" : "warning"](code === 0 ? "绑定成功" : msg);
        refData.visible = !(code === 0);
        code === 0 && emit('handleSuccess');
      } catch (msg) {
        message.warning(msg);
      }
    };
    const [debounceConfirm] = useDebounce(confirm, 300);
    return {
      ...toRefs(refData),
      debounceConfirm
    };
  }
};