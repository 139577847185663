import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "proportion"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => _ctx.visible = $event),
    title: "绑定IMEI号",
    onConfirmOk: $setup.debounceConfirm,
    width: "480px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode(" IMEI号： ")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 18
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          size: "small",
          type: "text",
          modelValue: _ctx.imei,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.imei = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "onConfirmOk"]);
}